import Splide from '@splidejs/splide';

let helloBar = function (speed) {
  let self = {
    speed: speed,

    initSlider: function () {
      let splide = new Splide('#hellobar-slider', {
        type: 'loop',
        focus: 'center',
        width: '100vw',
        arrows: false,
        autoplay: true,
        interval: self.speed,
        pagination: false,
      }).mount();
    },

    init: function () {
      var windowData = window._THULEDATA || window._EXTRANETDATA;
      if (windowData !== undefined && windowData.IsEditing) {
        return;
      }

      self.initSlider();
    },
  };

  return self;
};

document.addEventListener('DOMContentLoaded', function () {
  let slideritems = document.querySelectorAll('.hello-bar-item');

  if (slideritems == null) return;

  var component = document.querySelectorAll('#hellobar-slider');
  var speed = +component[0].getAttribute('data-autoplayspeed');

  let theBar = new helloBar(speed);
  theBar.init();
});
